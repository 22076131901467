function setSelectedCompany(companyId) {
  return window.$http({
    url: '/api/v1/general/set-selected-company',
    method: 'put',
    params: {
      companyId,
    },
  });
}

export default setSelectedCompany;
