async function login(logintoken, pin) {
  return window.$http({
    url: '/api/v1/login-pin',
    method: 'post',
    data: {
      login_token: logintoken,
      pin,
    },
  });
}
export default login;
