const getters = {
  nextUrl: (state) => state.nextUrl,
};

const actions = {
  setNextUrl({ commit }, nextUrl) {
    commit('SET_NEXTURL', nextUrl);
  },
};

const mutations = {
  SET_NEXTURL: (state, nextUrl) => {
    state.nextUrl = nextUrl;
  },
};

const state = {
  nextUrl: null,
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
