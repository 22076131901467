import store from '@/store';

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(Buffer.from(base64, 'base64').toString('ascii').split('').map((c) => (`%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`))
    .join(''));

  return JSON.parse(jsonPayload);
}

function checkTokenExpiriesDate() {
  const token = store.getters['user/token'];
  // console.log('checkTokenExpiriesDate', store.getters['user/expiriesDate'], token.length);
  if (token.length > 0) {
    const tokenData = parseJwt(token);
    const expiriesDate = parseInt(tokenData.exp, 10);
    return (Math.round(new Date().getTime() / 1000) < expiriesDate);
  }

  if (store.getters['user/expiriesDate'].length > 0) {
    store.dispatch('user/logout');
    store.dispatch('dialogpopup/show', {
      title: 'The session has expired ',
      message: 'Please login again',
    });
  }
  return false;
}

export default checkTokenExpiriesDate;
