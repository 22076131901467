<template>
  <v-main class="pa-0 mt-14">
    <router-view/>
  </v-main>
</template>

<script>
export default {
  name: 'TheContent',
};
</script>
