async function login(logintoken, passwordOld, passwordNew, passwordRepeat, pin) {
  return window.$http({
    url: `/api/v1/registration/set-password/${logintoken}`,
    method: 'put',
    data: {
      password_old: passwordOld,
      password_new: passwordNew,
      password_repeat: passwordRepeat,
      pin,
    },
  });
}
export default login;
