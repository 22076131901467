<template>
  <v-navigation-drawer
    app
    right
    clipped
    v-model="visibility"
  >
    <v-list nav dense>
      <v-list-item
        v-for="page in filteredPages"
        :key="page.title"
        link
        :to="page.path"
      >
        <v-list-item-action>
          <v-icon>{{ page.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ page.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import pages from '@/variables/pages';
import hasRightForRoute from '@/helper/hasRightForRoute';
import findRouteByPath from '@/mixins/findRouteByPath';

export default {
  name: 'TheNavigation',
  mixins: [
    findRouteByPath,
  ],
  computed: {
    visibility: {
      get() { return this.$store.getters['navigation/visibility']; },
      set(visibility) { this.$store.dispatch('navigation/setVisibility', visibility); },
    },
    tokenExpiriesDate: {
      get() { return this.$store.getters['user/expiriesDate']; },
    },
    filteredPages() {
      const filteredPages = this.pages.filter((page) => {
        const route = this.findRouteByPath(page.path);
        if (route !== false && hasRightForRoute(route)) {
          return page;
        }
        return false;
      });
      return filteredPages;
    },
  },
  methods: {
    hideNavigation() {
      this.visibility = false;
    },
  },
  data: () => ({
    pages,
  }),
};
</script>
