import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/src/locale/en.ts';
import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { en },
    current: 'en',
  },
  theme: {
    themes: {
      light: {
        primary: '#ce0f69',
        secondary: colors.grey.darken2,
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
    },
  },
  icons: {
    iconFont: 'md',
  },
});
