<template>
  <div id="visionCockpit_header">
    <v-system-bar v-if="isFullyLoggedIn && !isPrimary && !isAdmin"
      app
      color="#CE0F69"
      height="36px"
    >
      <h3 class="flex text-center" style="color: white">
        Guest at {{ companyName }}
      </h3>
    </v-system-bar>
    <v-app-bar
      app
      clipped-right
      :elevation="3"
      height="76px"
    >
      <div class="logo mx-auto ml-md-1 order-2 order-md-1">
        <router-link to="/" class="footer__link">
          <img alt="Vision Tools" class=""
              src="@/assets/images/visiontools-bildanalyse-systeme.svg"
              width="200px">
        </router-link>
      </div>
      <the-header-navigation v-if="isFullyLoggedIn"
                            class="hidden-sm-and-down mx-lg-auto order-1 order-md-2"/>
      <v-app-bar-nav-icon v-if="isFullyLoggedIn" class="hidden-md-and-up"
                          @click="toggleNavigationVisibility"/>
      <user-settings v-if="isFullyLoggedIn" class="order-3 order-md-3"/>
    </v-app-bar>
  </div>
</template>

<script>

import checkTokenExpiriesDate from '@/helper/checkTokenExpiriesDate';
import TheHeaderNavigation from '@/views/layout/TheHeaderNavigation.vue';
import UserSettings from '@/views/layout/UserSettings.vue';

export default {
  name: 'TheHeader',
  components: {
    UserSettings,
    TheHeaderNavigation,
  },
  computed: {
    navigationVisibility: {
      get() { return this.$store.getters['navigation/visibility']; },
      set(visibility) { this.$store.dispatch('navigation/setVisibility', visibility); },
    },
    filterBtnVisibility: {
      get() { return this.$store.getters['categories/btnVisibility']; },
    },
    userLogged: {
      get() {
        // console.log('TheLayout', checkTokenExpiriesDate());
        return checkTokenExpiriesDate();
      },
    },
    isPrimary: {
      get() { return this.$store.getters['user/company']?.id === this.$store.getters['user/mainCompany']?.id; },
    },
    companyName: {
      get() { return this.$store.getters['user/company']?.name; },
    },
    isAdmin: {
      get() { return this.$store.getters['user/isAdmin']; },
    },
    isFullyLoggedIn: {
      get() { return this.$store.getters['user/isFullyLoggedIn']; },
    },
  },
  methods: {
    toggleNavigationVisibility() {
      this.navigationVisibility = !this.navigationVisibility;
    },
    showBottomSheet() {
      this.$store.dispatch('categories/setBottomSheetVisibility', true);
    },
  },
};
</script>
