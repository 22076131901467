<template>
  <v-dialog max-width="400" v-model="visibility" persistent>
    <v-card>
      <v-card-text class="pt-2">
        {{ message }}
        <v-progress-linear
          indeterminate
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogLoader',
  computed: {
    message: {
      get() { return this.$store.getters['dialogloader/message']; },
    },
    visibility: {
      get() { return this.$store.getters['dialogloader/visibility']; },
      set(value) { this.$store.dispatch('dialogloader/visibility', value); },
    },
  },
};
</script>
