<template>
  <v-footer
    color="#f5f5f5"
    padless
    class="footer pb-3"
  >
    <v-container>
    <v-row
      v-if="userLogged"
      class="justify-center"
      no-gutters
    >
      <v-btn
        v-for="(link, index) in footerLinks"
        :key="index"
        color="white"
        text
        rounded
        :ripple="false"
        class="my-2 footer__links"
      >
        <a :href="link.link" class="footer__link" rel="noreferrer noopener" target='_blank'
           v-if="link.external">
          <i aria-hidden="true"
             class="v-icon notranslate theme--light"
             :class="link.iconClass.length ? link.iconClass : ''">
          </i>
          {{ link.linkText }}
        </a>
        <router-link :to="link.link" class="footer__link" v-else>
          <i aria-hidden="true"
             class="v-icon notranslate theme--light"
             :class="link.iconClass.length ? link.iconClass : ''">
          </i>
          {{ link.linkText }}
        </router-link>
      </v-btn>
    </v-row>
    <v-row
      class="justify-center"
      no-gutters
    >

      <div class="footer__link-wrap my-2 pr-3 pl-3" style="font-size:12px;padding-top:5px;">
        &copy;{{ new Date().getFullYear() }} VisionTools Bildanalyse Systeme
      </div>
      <div
        v-for="link in legalLinks"
        :key="link.link"
        class="footer__link-wrap my-2 pr-3 pl-3"
      >
        <a :href="link.link" class="footer__link" rel="noreferrer noopener"
           target='_blank'>
          {{ link.linkText }}
        </a>
      </div>
      <div class="footer__link-wrap my-2 pr-3 pl-3" style="font-size:12px;padding-top:5px;">
        {{ version }}
      </div>
    </v-row>
    </v-container>
  </v-footer>
</template>
<script>
import checkTokenExpiriesDate from '../../helper/checkTokenExpiriesDate';
import version from '../../../version.json';

export default {
  data: () => ({
    footerLinks: [
      {
        linkText: 'FAQ',
        link: '/help',
        external: false,
        iconClass: 'mdi mdi-progress-question',
      },
      {
        linkText: 'support@vision-cockpit.com',
        link: 'mailto:support@vision-cockpit.com',
        external: true,
        iconClass: 'mdi mdi-email-outline',
      },
    ],
    legalLinks: [
      {
        linkText: 'Legal notice',
        link: 'https://www.vision-tools.com/en/legal-notice.html',
        external: true,
      },
      {
        linkText: 'Privacy Policy',
        link: 'https://www.vision-tools.com/en/privacy-policy.html',
        external: true,
      },
    ],
    version: version.version,
  }),
  computed: {
    userLogged: {
      get() {
        return checkTokenExpiriesDate();
      },
    },
  },
};
</script>

<style lang="scss">
.footer {
  .v-btn {
    background-color: transparent;
  }
  .footer__links .footer__link {
    background-color: transparent;
    font-size: 16px;
  }
}
</style>
