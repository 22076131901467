import apiLogin from '@/api/auth/login';
import apiLoginPin from '@/api/auth/loginPin';
import apiLoginFirstTime from '@/api/auth/loginFirstTime';
import apiGetUser from '@/api/user/getUser';
import apiSetSelectedCompany from '@/api/user/setSelectedCompany';

const getters = {
  id: (state) => state.id,
  token: (state) => state.token,
  firstname: (state) => state.firstname,
  lastname: (state) => state.lastname,
  email: (state) => state.email,
  mobileNumber: (state) => state.mobileNumber,
  roles: (state) => state.roles,
  isActive: (state) => state.isActive,
  isAdmin: (state) => state.isAdmin,
  logintoken: (state) => state.logintoken,
  expiriesDate: (state) => state.expiriesDate,
  isFullyRegistered: (state) => state.isFullyRegistered,
  qrCode: (state) => state.qrCode,
  firstPassword: (state) => state.firstPassword,
  canManageFilestorage: (state) => state.canManageFilestorage,
  canStartTrainings: (state) => state.canStartTrainings,
  company: (state) => state.company,
  isFullyLoggedIn: (state) => state.isFullyLoggedIn,
  userState: (state) => state,
  companies: (state) => state.companies,
  mainCompany: (state) => state.mainCompany,
  dataset: (state) => state.dataset,
};

const actions = {
  async login({ commit }, { username, password }) {
    const response = await apiLogin(username, password);
    if (response) {
      commit('SET_LOGINTOKEN', response.data.login_token);
      commit('SET_ISFULLREGISTERED', response.data.is_fully_registered);
      if (response.data.is_fully_registered === false) {
        commit('SET_QRCODE', response.data.qr_code);
        commit('SET_FIRSTPASSWORD', password);
      }
    }
  },
  async loginPin({ commit }, { logintoken, pin }) {
    const response = await apiLoginPin(logintoken, pin);
    if (response) {
      commit('SET_TOKEN', response.data.token);
      return apiGetUser().then((user) => {
        commit('SET_USER', user);
        return user;
      });
    }
    return response;
  },
  async companyUser({ commit }, companyId) {
    const response = await apiSetSelectedCompany(companyId);
    if (response) {
      commit('SET_TOKEN', response.data.jwt_token);
      commit('SET_COMPANY_USER', companyId);
    }
  },
  async loginFirstTime({ commit }, {
    logintoken, passwordOld, passwordNew, passwordRepeat, pin,
  }) {
    const response = await apiLoginFirstTime(
      logintoken,
      passwordOld,
      passwordNew,
      passwordRepeat,
      pin,
    );
    commit('SET_FIRSTPASSWORD', '');
    commit('SET_QRCODE', '');
    if (response) {
      commit('SET_TOKEN', response.data.token);
      apiGetUser().then((user) => {
        commit('SET_USER', user);
      });
    }
  },
  async updateUser({ commit }) {
    const response = await apiGetUser();
    if (response) {
      return apiGetUser().then((user) => {
        commit('SET_USER', user);
        return user;
      });
    }
    return response;
  },
  async setDataset({ commit }, { ds }) {
    commit('SET_DATASET', ds);
  },
  logout({ commit }) {
    commit('LOGOUT');
  },
};

const mutations = {
  SET_LOGINTOKEN: (state, logintoken) => { state.logintoken = logintoken; },
  SET_ISFULLREGISTERED: (state, isFullyRegistered) => {
    state.isFullyRegistered = isFullyRegistered;
  },
  SET_QRCODE: (state, logintoken) => { state.qrCode = logintoken; },
  SET_TOKEN: (state, token) => { state.token = token; },
  SET_FIRSTPASSWORD: (state, password) => { state.firstPassword = password; },
  SET_EXPIRIESDATE: (state, expiriesDate) => { state.expiriesDate = expiriesDate; },
  SET_USER: (state, user) => {
    state.id = user.data.id;
    state.firstname = user.data.firstname;
    state.lastname = user.data.lastname;
    state.email = user.data.email;
    state.mobileNumber = user.data.mobile_number;
    state.isActive = user.data.is_active;
    state.expiriesDate = user.data.expiriesDate;
    state.companies = user.data.companies;
    state.isAdmin = user.data.is_admin;
    state.mainCompany = user.data.main_company;
  },

  SET_COMPANY_USER: (state, companyId) => {
    if (companyId === 0) {
      state.isAdmin = true;
      state.roles = ['ROLE_ADMIN'];
    } else {
      for (let i = 0; i < state.companies.length; i += 1) {
        if (state.companies[i].company.id === companyId) {
          state.isAdmin = false;
          state.company = state.companies[i].company;
          state.roles = state.companies[i].roles;
          state.canManageFilestorage = state.companies[i].can_manage_filestorage;
          state.canStartTrainings = state.companies[i].can_start_trainings;
        }
      }
    }
    state.isFullyLoggedIn = true;
  },

  SET_DATASET: (state, dataset) => { state.dataset = dataset; },

  LOGOUT: (state) => {
    state.id = '';
    state.firstname = '';
    state.lastname = '';
    state.email = '';
    state.firstPassword = '';
    state.roles = [];
    state.isActive = false;
    state.logintoken = '';
    state.token = '';
    state.expiriesDate = '';
    state.isAdmin = false;
    state.isFullyRegistered = false;
    state.isFullyLoggedIn = false;
    state.qrCode = '';
    state.dataset = null;
    state.mainCompany = null;
    state.company = null;
    state.companies = [{
      roles: [], canManageFilestorage: false, canStartTrainings: false,
    }];
  },
};

const state = {
  id: '',
  firstname: '',
  lastname: '',
  email: '',
  isActive: false,
  logintoken: '',
  token: '',
  expiriesDate: '',
  isAdmin: false,
  isFullyRegistered: false,
  isFullyLoggedIn: false,
  qrCode: '',
  firstPassword: '',
  company: null,
  mainCompany: null,
  roles: null,
  dataset: null,
  companies: [
    {
      roles: [],
      canManageFilestorage: false,
      canStartTrainings: false,
    },
  ],
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
