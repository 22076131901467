export default {
  methods: {
    findRouteByPath(path) {
      let route = false;
      const pagePath = path.replace(/^[/ ]+|[/ ]+$/g, '');
      for (let i = 0; i < this.$router.options.routes.length; i += 1) {
        route = this.checkRouteWithPath(this.$router.options.routes[i], pagePath);
        if (route !== false) {
          return route;
        }
      }
      return route;
    },
    checkRouteWithPath(route, pagePath) {
      const routePath = route.path.replace(/^[/ ]+|[/ ]+$/g, '');
      if (routePath === pagePath) {
        return route;
      }
      if (route.children !== undefined && route.children.length > 0) {
        for (let i = 0; i < route.children.length; i += 1) {
          const childRoute = this.checkRouteWithPath(route.children[i], pagePath);
          if (childRoute !== false) {
            return childRoute;
          }
        }
      }
      return false;
    },
  },
};
