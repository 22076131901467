const userSettingsPages = [
  {
    title: 'Help',
    path: '/help',
    icon: 'mdi-progress-question',
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: 'mdi-tune',
  },
  {
    title: 'Settings',
    path: '/admin/settings',
    icon: 'mdi-tune',
  },
];

export default userSettingsPages;
