import Vue from 'vue';
import VueRouter from 'vue-router';
import TheLayout from '@/views/layout/TheLayout.vue';
import isValidUser from '@/helper/checkTokenExpiriesDate';
import hasRightForRoute from '@/helper/hasRightForRoute';
import store from '@/store/';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: TheLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: 'auth' */'@/views/LoginPage.vue'),
        meta: {
          requiresAuth: false,
        },
        children: [
        ],
      },
      {
        name: 'login',
        path: 'login',
        component: () => import(/* webpackChunkName: 'auth' */'@/views/LoginPage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: 'Login PIN',
        path: 'loginpin',
        component: () => import(/* webpackChunkName: 'auth' */'@/views/LoginPinPage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: 'Customer User',
        path: 'customeruser',
        component: () => import(/* webpackChunkName: 'user' */'@/views/CustomerUserPage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: 'Login First Time',
        path: 'loginfirsttime',
        component: () => import(/* webpackChunkName: 'auth' */'@/views/LoginFirstTimePage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'logout',
        alias: ['firstlogin', 'reset'],
        component: () => import(/* webpackChunkName: 'auth' */'@/views/LogoutPage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'my-account',
        component: () => import(/* webpackChunkName: 'user' */'@/views/UserAccountPage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'forgot-password',
        component: () => import(/* webpackChunkName: 'auth' */'@/views/UserForgotPasswordPage.vue'),
        meta: {
          requiresAuth: false,
        },
        props: true,
      },
      {
        path: 'forgot-password/:token',
        component: () => import(/* webpackChunkName: 'auth' */'@/views/UserForgotPasswordPage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: '404',
        path: '404',
        component: () => import(/* webpackChunkName: 'front' */'@/views/NotFoundPage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        name: '403',
        path: '403',
        component: () => import(/* webpackChunkName: 'front' */'@/views/AccessDeniedPage.vue'),
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: 'admin/users',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/Admin/User/UsersPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_ADMIN'],
        },
      },
      {
        path: 'admin/companies',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/Admin/Company/CompaniesPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_ADMIN'],
        },
      },
      {
        path: '/admin/settings',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/Admin/SettingsPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_ADMIN'],
        },
      },
      {
        path: '/reports',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/Admin/Reports/DashboardPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_ADMIN'],
        },
      },
      {
        path: '/reports/billing-history',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/Admin/Reports/BillingHistoryPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_ADMIN'],
        },
      },
      {
        path: '/reports/billing-details',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/Admin/Reports/BillingDetailsPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_ADMIN'],
        },
      },
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: 'user' */'@/views/DashboardPage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'customer/trainings',
        component: () => import(/* webpackChunkName: 'user' */'@/views/Training/TrainingsPage.vue'),
        meta: {
          requiresAuth: true,
          user: 'user.canStartTrainings',
          authorize: ['ROLE_CUSTOMER', 'ROLE_USER'],
        },
      },
      {
        path: 'customer/testings',
        component: () => import(/* webpackChunkName: 'user' */'@/views/Testings/TestingsPage.vue'),
        meta: {
          requiresAuth: true,
          user: 'user.canStartTrainings',
          authorize: ['ROLE_CUSTOMER', 'ROLE_USER'],
        },
      },
      {
        path: 'customer/users',
        component: () => import(/* webpackChunkName: 'user' */'@/views/Customer/User/UsersPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_CUSTOMER'],
        },
      },
      {
        path: 'customer/report-sheet',
        component: () => import(/* webpackChunkName: 'user' */'@/views/Customer/Reports/ReportSheetPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_CUSTOMER'],
        },
      },
      {
        path: 'filemanager/list',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/Filemanager/FilemanagerPage.vue'),
        meta: {
          requiresAuth: true,
          user: 'user.canManageFilestorage',
          authorize: ['ROLE_CUSTOMER', 'ROLE_USER'],
        },
      },
      {
        path: 'filemanager/downloads',
        component: () => import(/* webpackChunkName: 'user' */'@/views/Filemanager/DownloadsPage.vue'),
        meta: {
          requiresAuth: true,
          user: 'user.canManageFilestorage',
          authorize: ['ROLE_CUSTOMER', 'ROLE_USER'],
        },
      },
      {
        path: '/labelling',
        component: () => import(/* webpackChunkName: 'user' */'@/views/LabellingPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_CUSTOMER', 'ROLE_USER'],
        },
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/SettingsPage.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_CUSTOMER', 'ROLE_USER'],
        },
      },
      {
        path: 'settings/billing/details',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/BillingDetails.vue'),
        meta: {
          requiresAuth: true,
          authorize: ['ROLE_CUSTOMER', 'ROLE_USER'],
        },
        props: true,
      },
      {
        path: 'help',
        component: () => import(/* webpackChunkName: 'admin' */'@/views/HelpPage.vue'),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log('router js', to, hasRightForRoute(to), from, next);
  const isFullyLoggedIn = store.getters['user/isFullyLoggedIn'];
  if ((to.path === '/' || to.path === '/login' || to.path === '/loginpin')
    && from.path !== '/loginpin' && from.path !== '/loginfirsttime'
    && hasRightForRoute(to) && isValidUser()) {
    if (isFullyLoggedIn) {
      next({ path: '/dashboard' });
    } else {
      next({ path: '/logout' });
    }
  }
  if (to.path === '/dashboard' && from.path === '/'
    && hasRightForRoute(to) && isValidUser() && !isFullyLoggedIn) {
    next({ path: '/customeruser' });
  }
  if (hasRightForRoute(to)) {
    next();
  } else if (isValidUser()) {
    next({ path: '/403' });
  } else {
    store.dispatch('returnUrl/setNextUrl', to.fullPath);
    next({
      path: '/',
    });
  }
});
//
// router.beforeEach((to, from, next) => {
//   // console.info("route from " + from.name + " to " + to.name);
//   to.meta.from = from;
//
//   (async () => {
//     const auth = (to.meta.auth === undefined) ? true : to.meta.auth;
//
//     if (to.meta.redirectIfAuth && store.getters.isLoggedIn) {
//       next(false);
//       router.push({ name: 'home' });
//     } else if (auth && !store.getters.isLoggedIn) {
//       router.app.$error('Zugriff verweigert!');
//       next(false);
//     } else {
//       // document.title = `${`${to.meta.title} | ${APP.title}` || APP.title} ${APP.version}`;
//       //
//       // document.body.className = document.body.className.replace(
//       //   /(layout-.[^\s]+)/g,
//       //   '',
//       // );
//       // document.body.classList.add(`layout-${to.meta.layout || 'default'}`);
//       //
//       // router.app.$system().session.checkRefreshToken();
//       next();
//     }
//   })();
// });

export default router;
