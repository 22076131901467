import store from '@/store';
// import routes from '@/router/index';
import isValidUser from '@/helper/checkTokenExpiriesDate';

function hasRightForRoute(route) {
  if (route.meta.requiresAuth === false) {
    return true;
  }
  if (isValidUser()) {
    if (route.meta.authorize === undefined
      || (Array.isArray(route.meta.authorize) && route.meta.authorize.length === 0)) {
      return true;
    }
    const userRoles = store.getters['user/roles'];
    const isAdmin = store.getters['user/isAdmin'];
    let isAuthorized = false;
    for (let i = 0; i < route.meta.authorize.length; i += 1) {
      if (userRoles.includes(route.meta.authorize[i]) || isAdmin) {
        isAuthorized = true;
      }
    }
    if (isAuthorized && (route.meta.user !== undefined
      && route.meta.user.length > 0)) {
      if (isAdmin) {
        isAuthorized = false;
      } else {
        if (
          route.meta.user.trim() === 'user.canManageFilestorage'
          && store.getters['user/canManageFilestorage'] !== true) {
          isAuthorized = false;
        }
        if (
          route.meta.user.trim() === 'user.canStartTrainings'
          && store.getters['user/canStartTrainings'] !== true) {
          isAuthorized = false;
        }
      }
    }
    if (isAuthorized && isAdmin) {
      for (let i = 0; i < route.meta.authorize.length; i += 1) {
        if (!userRoles.includes(route.meta.authorize[i])) {
          isAuthorized = false;
        }
      }
    }
    return isAuthorized;
  }
  return false;

  // const filteredRoutes = [];

  // this.$router.options.routes.forEach((routeItem) => {
  //   if (routeItem.path === route) {
  //     filteredRoutes.push(routeItem);
  //   }
  // });
  // switch (filteredRoutes.length) {
  //   case 0:
  //     return false;
  //   case filteredRoutes.length > 1:
  //     // eslint-disable-next-line no-throw-literal
  //     throw 'There is a route with more than one page defined';
  //   case 1:
  //     const rights = filteredRoutes.pop().children.meta;
  //     if (rights.requiresAuth === false) {
  //       return true;
  //     }
  //     break;
  //   default:
  //     return false;
  // }
  // return false;
}

export default hasRightForRoute;
