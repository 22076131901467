import Vue from 'vue';
import Vuex from 'vuex';
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueLazyLoad from 'vue-lazyload';
import ElementUI from 'element-ui';
import moment from 'moment';
import Http from './service/http';
import 'leaflet/dist/leaflet.css';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import validation from './plugins/validation';
import filter from './plugins/filter';
import '@/assets/scss/main.scss';
import i18n from './i18n';
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$log = console.log;

Vue.config.productionTip = false;

Vue.use(validation);
Vue.use(filter);
Vue.use(Vuex);
Vue.use(CKEditor);
Vue.use(VueLazyLoad);
Vue.use(ElementUI);

Vue.mixin({
  methods: {
    format_date(value, withTime = false, utc = true) {
      let format = 'DD.MM.YYYY';
      if (withTime) {
        format = 'DD.MM.YYYY HH:mm';
      }
      if (value) {
        if (utc) {
          return `${`${moment.utc(value).local().format(format)}`}`;
        }
        return `${`${moment(value).format(format)}`}`;
      }
      return 'unknown';
    },
  },
});

window.$http = new Http(store, router);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
