<template>
  <v-app>
    <TheHeader/>
    <MobileNavigation v-if="userLogged" class="hidden-md-and-up"/>
    <TheContent/>
    <NotificationBar/>
    <DialogPopup/>
    <DialogLoader/>
    <TheFooter v-if="!loginPage"/>
  </v-app>
</template>

<script>
import TheHeader from '@/views/layout/TheHeader.vue';
import TheContent from '@/views/layout/TheContent.vue';
import TheFooter from '@/views/layout/TheFooter.vue';
import MobileNavigation from '@/views/layout/TheNavigation.vue';
import NotificationBar from '@/components/NotificationBar.vue';
import DialogPopup from '@/components/DialogPopup.vue';
import DialogLoader from '@/components/DialogLoader.vue';
import checkTokenExpiriesDate from '@/helper/checkTokenExpiriesDate';

export default {
  name: 'TheLayout',
  components: {
    TheHeader,
    MobileNavigation,
    TheContent,
    NotificationBar,
    DialogPopup,
    DialogLoader,
    TheFooter,
  },
  computed: {
    navigationKey: {
      get() { return this.$store.getters['navigation/key']; },
    },
    userLogged: {
      get() {
        // console.log('TheLayout', checkTokenExpiriesDate());
        return checkTokenExpiriesDate();
      },
    },
    loginPage: {
      get() { return this.$route.path === '/' || this.$route.path === '/login'; },
    },
  },
};
</script>
