export default {
  install(Vue) {
    /* eslint-disable no-param-reassign */
    Vue.prototype.$rules = {
      required: [
        (v) => !!v || 'Field is required.',
      ],
      email: [
        (v) => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Incorrect e-mail.',
      ],
      minLength: [
        (v) => v.length >= 8 || 'Password should contain at least 8 characters.',
      ],
      postcode: [
        (v) => /^[0-9]{1,5}$/.test(v) || 'Invalid postcode format.',
      ],
      logoSize: [
        (file) => (file && file.size < 2097152) || 'File size should not exceed 2MB.',
      ],
      imagesSize: [
        (files) => !files || !files.some((file) => file.size > 2097152) || 'The size of each file should not exceed 2MB.',
      ],
      comboboxRequired: [
        (v) => v.length !== 0 || 'Please select at least one tag.',
      ],
      onlyNumbers: [
        (v) => /^[1-9][0-9]*$/.test(v) || 'Only numbers without leading zero.',
      ],
      phone: [
        (v) => /^[+]?[0-9-]*$/.test(v) || 'Only numbers (or leading plus or zero).',
      ],
    };
    /* eslint-enable no-param-reassign */
  },
};
