<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>
<template>
  <div id="app">
    <component :is="layout">
      <router-view :layout.sync="layout" />
    </component>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.title = 'VisionCockpit';
  },
  data() {
    return {
      layout: 'div',
    };
  },
};
</script>
