<template>
  <v-list dense class="user-settings">
    <v-list-item class="user-menu">
      <v-menu offset-y min-width="200px" nudge-right="20px">
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :ripple="false"
            class="user-settings__button"
          >
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-card class="user-settings">
          <v-list class="user-settings__submenu">
            <v-subheader v-if="this.$store.getters['user/lastname']"
                         class="user-settings__submenu--name mb-2">
              {{
                this.$store.getters['user/firstname'] + ' ' + this.$store.getters['user/lastname']
              }}
            </v-subheader>
            <v-list-item v-if="this.$store.getters['user/company']
            && this.$store.getters['user/company']['name'] !== undefined
            && !this.$store.getters['user/isAdmin']">
              <v-list-item-content class="user-settings__submenu--companyname"
                v-if="companyIsMainCompany()">
                  {{
                    this.$store.getters['user/company']['name']
                  }}
              </v-list-item-content>
              <v-list-item-content class="user-settings__submenu--companyname" v-else>
                Guest at
                {{
                  this.$store.getters['user/company']['name']
                }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="this.$store.getters['user/email']">
              <v-list-item-content class="user-settings__submenu--email">
              <a :href="'mailto://' + this.$store.getters['user/email']" target="_blank">
                {{
                  this.$store.getters['user/email']
                }}
              </a>
            </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="page in notifications"
              :key="page.title"
              link
              :to="page.path"
              :title="page.title"
              :ripple="false"
              class="mt-1"
            >
              <v-list-item-content>
                <v-list-item-title>{{ page.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              key="Sign out"
              link
              to="/logout"
              title="Sign out"
              :ripple="false"
              class="user-settings__sign-out"
            >
              <v-list-item-content>
                <v-list-item-title>Sign out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-list-item>
    <v-list-item
      v-for="page in filteredPages"
      :key="page.title"
      link
      :to="page.path"
      :title="page.title"
      :ripple="false"
    >
      <v-list-item-action>
        <v-icon>{{ page.icon }}</v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import pages from '@/variables/userSettingsPages';
import hasRightForRoute from '@/helper/hasRightForRoute';
import findRouteByPath from '@/mixins/findRouteByPath';

export default {
  name: 'UserSettings',
  mixins: [
    findRouteByPath,
  ],
  computed: {
    tokenExpiriesDate: {
      get() {
        return this.$store.getters['user/expiriesDate'];
      },
    },
    filteredPages() {
      const filteredPages = this.pages.filter((page) => {
        const route = this.findRouteByPath(page.path);
        if (route !== false && hasRightForRoute(route)) {
          return page;
        }
        return false;
      });
      return filteredPages;
    },
  },
  data: () => ({
    pages,
    notifications: [
      {
        id: 1,
        title: 'Profile settings',
        path: '/my-account',
      },
    ],
  }),
  methods: {
    onSignOut() {
      this.$refs.confirm.open('Sign out', 'Sign out?')
        .then((confirm) => {
          if (confirm) {
            this.$store.dispatch('dialogloader/show', 'The user is being deleted...');
          }
        });
    },
    companyIsMainCompany() {
      const company = this.$store.getters['user/company'];
      const mainCompany = this.$store.getters['user/mainCompany'];
      if ((company !== undefined) && (mainCompany !== undefined)) {
        return (company.id === mainCompany.id);
      }
      return false;
    },
  },
};
</script>
<style lang="scss">
header .user-menu {
  margin-left: -13px;
  margin-right: 15px;
}
</style>
